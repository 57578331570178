// Types

export interface ISubmitApplicationResult {
    workflow: IWorkflow;
    status: ApplicantionStatus;
    links: ILinks;
}

export interface ISubmitApplicationResultDto {
    workflow: IWorkflow;
    status: ApplicantionStatus;
    _links: ILinks;
}

export interface ILinks {
    self: ILink;
    [key: string]: ILink
}

export interface ILink {
    href: string;
}

export interface IWorkflow {
    previous: LinkRelation;
    next: LinkRelation;
    complete: boolean;
}

// Enums

export enum ApplicantionStatus {
    Initiated = "initiated",
    Started = "started",
    InProgress = "in_progress"
}

export enum LinkRelation {
    Applicant = 'gpa:confirm-applicant',
    ApplicantDetails = 'gpa:applicant-details',
    ApplicantDocuments = 'gpa:applicant-documents',
    BusinessType = 'gpa:business-type',
    BusinessProfile = 'gpa:business-profile',
    Documents = 'gpa:documents',
    Representatives = 'gpa:representatives',
    ConfirmRepresentatives = 'gpa:confirm-representatives',
    PayoutAccount = 'gpa:payout-account',
    Submit = 'gpa:submit',
    Redirect = 'gpa:redirect'
}

export enum DocumentType {
    SelfieWithId = 'selfie_with_id',
    Passport = 'passport',
    Id = 'id',
    DrivingLicense = 'driving_license',
    CompanyRegistration = 'company_registration',
    BankAccountProof = 'bank_account_proof',
}

export enum DocumentSide {
    Front = 'front',
    Back = 'back'
}

export enum RegistrationNumberCode {
    Vat = "Vat",
    DeHrb = "DeHrb",
    DeHra = "DeHra",
    DeVr = "DeVr",
    SiTin = "SiTin",
    EeKmkr = "EeKmkr"
}

export enum Role {
    Executive = "executive",
    Owner = "owner",
    Director = "director",
}
